import React from "react";
import { graphql, Link } from "gatsby";
import ContentBlock from "../components/LandingContentBlock/ContentBlock";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle/PageTitle";
import SEO from "../components/SEO/SEO";

import './scss/blog.scss';


export default ({ data: {allMarkdownRemark}}) => {
    const { nodes } = allMarkdownRemark;
    console.log(nodes)
    return (
    <Layout>
        <SEO title="Zest 4 Maths | Blog | Learn "/>
        <PageTitle title="Zest Journal" />
        <ContentBlock class={'sm-content'}>
            {nodes.map((blogItem) => {
                return (
                    <BlogItem frontmatter={blogItem.frontmatter} />
                )
            })}
        </ContentBlock>
    </Layout>
    )
};

const BlogItem = (props) => {
    return(
        <div className="blog-item">
           
                <Link to={props.frontmatter.slug}>
                <h2 className="blog-item__title">
                    {props.frontmatter.title}
                    </h2>
                </Link>
            
            <div className="blog-item__secondary">
                <div className="blog-item__secondary__date">
                    {props.frontmatter.date}
                </div>    
            </div>
        </div>
    )
}

export const blogPosts = graphql`
query blogPosts {
    allMarkdownRemark {
      nodes {
        frontmatter {
          title
          slug
          date(formatString: "MMMM DD, YYYY")
          description
        }
      }
    }
  }
`